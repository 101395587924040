<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'bill',
  created() {
    this.getConfigList('bill_check');
  },
  methods: {
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'view') {
        window.open(`${row.invoiceUrl}`);// 点击发票跳转到另外一个界面
      }
    },
  },
};
</script>
